/* src/components/Header.css */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #018ebe;
    color: #ffffff;
    font-size: 16px;
  }
  
  .header-left .logo {
    height: 20px;
    cursor: pointer;
  }
  
  .header-right .logout-link {
    background: none;
    border: none;
    color: #ffffff;
    cursor: pointer;
    font-size: 16px;
  }
  
  .header-right .logout-link:hover {
    text-decoration: underline;
  }
  